<script>
import Icon from './Icon'

export default {
  name: 'NavMenuItem',
  components: { Icon },
  props: {
    item: { type: [Object, String], required: true },
  },
  render(h) {
    let tagName = 'nuxt-link'
    const icon = h(Icon, {
      props: { icon: this.item.icon },
      class: 'mr-2 text-base',
    })
    const childs = [icon]

    if (!this.item.mini) {
      childs.push(
        h(
          'span',
          { class: ' text-xs' },
          this.item.label,
        ),
      )
    }

    const data = {
      props: {
        to: this.item.to,
      },
      class: {
        'block p-4 border-l-4 no-underline text-sm': true,
        'text-center': this.item.mini,
      },
    }

    if (typeof this.item.to !== 'function') {
      data.props.exact = !!this.item.exact
      data.props.activeClass = 'border-torquise text-torquise hover:text-white border-l-4'
    }
    else {
      tagName = 'a'
      data.attrs = {
        href: 'javascript:void(0)',
      }
      data.on = {
        click: this.item.to,
      }
    }

    return h('li', {
      class: 'transition duration-300 ease-in-out text-grey-dark hover:bg-torquise hover:text-white hover:scale-125 ',
    }, [h(tagName, data, childs)])
  },
}
</script>
