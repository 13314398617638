export function state() {
  return {
    all: [],
    selected: null,
    terminalPasswordPopup: {
      release: false,
      show: false,
    },
    employeeLoginPopup: {
      show: false,
    },
    pickedTerminal: {},
  }
}

export const mutations = {
  SET_ITEMS: (state, items) => (state.all = items),
  SET_SELECTED: (state, selected) => {
    state.selected = selected
  },
  SET_TERMINAL_PASSWORD_POPUP(state, payload) {
    state.terminalPasswordPopup = payload
  },
  SET_EMPLOYEE_LOGIN_POPUP(state, payload) {
    state.employeeLoginPopup = payload
  },
  SET_PICKED_TERMINAL(state, terminal) {
    state.pickedTerminal = terminal
  },
}

export const actions = {

  async fetch({ commit, dispatch }, slug) {
    try {
      dispatch('wait/start', 'terminals.index', { root: true })

      const { data } = await this.$axios.$get(`/terminals`, {
        headers: {
          'X-Company-Id': localStorage.getItem('company-id'),
          'x-location': slug,
        },
      })

      commit('SET_ITEMS', data)
    }
    catch (e) {
      console.log(e)
    }
    finally {
      dispatch('wait/end', 'terminals.index', { root: true })
    }
  },
  async lock({ commit, dispatch }, terminal) {
    try {
      // await dispatch('wait/start', 'terminals.lock', { root: true })
      const t = typeof terminal === 'string' ? JSON.parse(terminal) : terminal
      const id = t?.id
      if (!id)
        return false

      const res = await this.$axios.put(
        `/terminals/${id}/lock`,
        { password: 'password' },
        {
          headers: {
            'X-Company-Id': localStorage.getItem('company-id'),
          },
        },
      )
      const { data: resTerminalData } = res.data
      const { terminal: updatedTerminal, token } = resTerminalData

      // const locationSlug = //rootGetters.currentLocation.slug

      await commit('SET_SELECTED', { ...updatedTerminal, token })
      // await dispatch('fetch', locationSlug)
      return true
    }
    catch (e) {
      console.log('terminal already in use')
      return false // important
    }
    finally {
      dispatch('wait/end', 'terminals.lock', { root: true })
    }
  },
  async check({ commit, getters }) {
    const { id, token } = getters.actualTerminal || {}
    if (!!id && token) {
      try {
        const { data } = await this.$axios.$post(
          `terminals/${id}/check`,
          { token },
          {
            headers: {
              'X-Company-Id': localStorage.getItem('company-id'),
            },
          },
        )
        commit('SET_SELECTED', { ...data, token })
      }
      catch (e) {
        commit('SET_SELECTED', null)
      }
    }
    else {
      commit('SET_SELECTED', null)
    }
  },
  async release({ rootGetters, dispatch }, terminal) {
    const { id } = terminal
    try {
      dispatch('wait/start', `terminals.release.${id}`, { root: true })

      const { token, password } = terminal
      const locationSlug = rootGetters.currentLocation.slug

      await this.$axios.$put(
        `terminals/${id}/release`,
        {
          token,
          password,
        },
        {
          headers: {
            'X-Company-Id': localStorage.getItem('company-id'),
          },
        },
      )

      await dispatch('fetch', locationSlug)

      // return true so the swal message will close
      return true
    }
    catch (e) {
      return false // needed
    }
    finally {
      dispatch('wait/end', `terminals.release.${id}`, { root: true })
    }
  },
  async selectAndLockTerminal({ commit, dispatch }, { terminal, route }) {
    const lockStatus = await dispatch('lock', terminal)
    if (!lockStatus) {
      // Displays alert in front end
      return false
    }
    const { slug } = route.params

    await this.$auth.$storage.setUniversal('mode', null)
    await this.$auth.$storage.setUniversal('location_slug', slug)

    localStorage.setItem('terminal', JSON.stringify(terminal))
    commit('SET_PICKED_TERMINAL', terminal)

    this.$router.push(`/pos/0`) // async

    return true // necessary or the popup will show anyway in the pos screen
  },
  showPasswordPopupForRelease({ commit }, terminal) {
    commit('SET_TERMINAL_PASSWORD_POPUP', { show: true, release: true })
    commit('SET_PICKED_TERMINAL', terminal)
  },
  hidePasswordPopup({ commit }) {
    commit('SET_TERMINAL_PASSWORD_POPUP', { show: false })
  },
  showLoginPopup({ commit }, terminal) {
    commit('SET_EMPLOYEE_LOGIN_POPUP', { show: true })
    commit('SET_PICKED_TERMINAL', terminal)
  },
  hideLoginPopup({ commit }) {
    commit('SET_EMPLOYEE_LOGIN_POPUP', { show: false })
  },
  clearSelectedTerminal({ commit }) {
    commit('SET_SELECTED', null)
    commit('SET_PICKED_TERMINAL', {})
  },
}

export const getters = {
  enabledTerminals: state =>
    state.all.filter((terminal) => {
      return terminal.enabled
    }),
  actualTerminal: state => state.selected,
}
