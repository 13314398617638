import get from 'lodash.get'
import moment from 'moment'

export function sanitizeTicketItem(item, locationId) {
  item = {
    item_id: item.id,
    addon_id: get(item, 'addon.id', null),
    color: item.color,
    price: item.price,
    pattern: item.pattern,
    qty: item.qty,
    subtotal: item.price * item.qty,
    tax:
      get(item, `prices.${locationId}.tax`, get(item, 'prices.global.tax', 0))
      * item.qty,
  }
  item.total = item.subtotal + item.tax
  return item
}

export function sanitizeTicket(ticket, locationId) {
  const item = { ...ticket }
  item.items = item.items.map(item => sanitizeTicketItem(item, locationId))

  item._ref = item.id
  item.customer_id = item.customer.id
  item.service_id = item.service.id
  item.total_pieces = item.totalPieces
  item.due_date = moment(item.due_date).format('YYYY-MM-DD HH:mm:ss')
  item.subtotal = ticket.subTotal

  delete item.totalPieces
  delete item.subTotal
  delete item.customer
  delete item.service
  delete item.coupons
  delete item.id

  return item
}
