export default function ({ route, store, redirect, $auth }) {
  const publicRoutes = ['/login', '/']
  const isLoggedIn = store.state.auth.loggedIn
  const hasToken = $auth.getToken('local')

  if (!isLoggedIn && publicRoutes.includes(route.path)) {
    return
  }

  if (isLoggedIn && publicRoutes.includes(route.path)) {
    return redirect('/location-select')
  }

  if (!isLoggedIn || !hasToken) {
    return redirect('/login') 
  }
}
