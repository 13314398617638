<script>
import AppButton from '../common/AppButton'
import Card from '../common/Card'
import Icon from '../Icon'

export default {
  name: 'CustomerSearchCard',
  components: { Icon, Card, AppButton },
  methods: {
    createCustomer() {
      this.$router.push({ name: 'customers-index-create' }, () => {
        this.$store.dispatch('ui/closeCustomerSearchModal')
      })
    },
  },
}
</script>

<template>
  <Card class="p-8">
    <div class="relative flex">
      <h3 class="mb-4 text-xl font-semibold leading-tight">
        Customer Search
      </h3>
      <div class="absolute top-0 right-0 ">
        <AppButton
          class="w-8 h-8 px-0 text-xs focus:outline-none "
          @click.native="createCustomer"
        >
          <Icon icon="plus" />
        </AppButton>
      </div>
    </div>
    <slot />
  </Card>
</template>

<style scoped></style>
