<script>
import Icon from '../Icon.vue'

export default {
  name: 'AppModal',
  components: {
    Icon,
  },
  props: {
    heading: { type: Boolean, default: true },
    hideClose: { type: Boolean, default: false },
    widthClass: { type: String, default: 'w-full' },
  },
  computed: {
    className() {
      const className = {}
      className[`${this.widthClass}`] = !!this.widthClass

      if (this.widthClass === 'w-full')
        className[`lg:max-w-xl`] = true

      return className
    },
  },
  methods: {
    closeClicked() {
      this.$emit('close')
    },
  },
}
</script>

<template>
  <div
    class="fixed inset-0 z-50 flex py-10 overflow-auto rounded bg-smoke-lighter "
    @click="closeClicked"
  >
    <div
      class="relative flex-col m-auto bg-white rounded-md shadow-lg"
      :class="className"
      @click.stop=""
    >
      <button
        v-show="hideClose"
        class="absolute top-0 right-0 z-30 p-4 text-xl text-white cursor-pointer pin-b focus:outline-none"
        @click="closeClicked"
      >
        <Icon icon="close" />
      </button>
      <div v-if="heading" class="flex">
        <div class="w-3/5 p-8 rounded-md">
          <slot name="left" />
        </div>
        <div class="w-2/5 p-8 rounded-md rounded-l-none bg-torquise">
          <slot name="right" />
        </div>
      </div>
      <div class="w-full">
        <slot />
      </div>
    </div>
  </div>
</template>
