export const actions = {
  async printReceipt({ rootGetters }, ticketId) {
    try {
      const headers = {
        headers: {
          'X-Company-Id': localStorage.getItem('company-id'),
          'x-location': rootGetters.currentLocation.slug,
        },
      }

      const params = {
        ticketId,
        locationId: rootGetters.currentLocation.id,
      }

      const data = await this.$axios.$post(`/printReceipt`, { params }, headers) // creating stm file or printing file

      return data?.msg
    }
    catch (error) {
      console.log(error)
      return error
    }
  },

  async printReceipts({ dispatch }, selectedTickets) {
    // go through all tickets
    const promises = selectedTickets.map(({ id }) => {
      // print the ticket
      return dispatch('printReceipt', id)
    })

    // sends the requests concurrently
    await Promise.all(promises)
  },
}
