<script>
import { makeTextInputHandler } from '../../utils/textInput'

export default {
  name: 'TextInput',

  props: {
    noAutoComplete: { type: Boolean, default: false },
    value: { type: [String, Number], default: null },
    label: { type: String, default: '' },
    labelClass: { type: String, default: '' },
    name: { type: String, default: null },
    inputClass: { type: String, default: '' },
    onChange: { type: Function, default: () => () => {} },
    placeholder: { type: String, default: '' },
    type: { type: String, default: 'text' },
    hasErrors: { type: Boolean },
    errors: { type: Object, default: null },
    prefix: { type: [String, Number, Boolean], default: false },
    required: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    paddingY: { default: '1', type: String },
    paddingX: { default: '1', type: String },
    maxLength: { default: null, type: String },
    mode: {
      type: String,
      default: 'raw',
      validator(value) {
        const validOptions = [
          'raw',
          'currency',
          'percentage',
          'phone',
          'card',
          'zip',
          'ccv',
        ]
        return validOptions.includes(value)
      },
    },
  },

  data: () => ({
    input: '',
    defaultClass: 'text-grey-darker leading-tight focus:outline-none',
    errorClass: 'border-red',
    showPassword: false,
  }),

  computed: {
    _errors() {
      return this.$parseErrors(this.errors)
    },
    inputType() {
      if (this.type === 'password' && !this.showPassword)
        return 'password'

      return 'text'
    },
    showPasswordVisibility() {
      return this.type === 'password'
    },
  },

  watch: {
    value(value) {
      this.inputHandler.setValue(value)
      this.$emit('input', value)
    },
  },

  mounted() {
    this.inputHandler = makeTextInputHandler(this.$el.querySelector('input'), {
      mode: this.mode,
      onValueChanged: this.onValueChanged,
      prefix: this.prefix,
    })
    this.inputHandler.setValue(this.value)
  },

  beforeDestroy() {
    this.inputHandler.destroy()
  },

  methods: {
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword
    },
    onValueChanged({ rawValue }) {
      this._value = rawValue
      this.$emit('input', this._value)
    },
    labelStyles() {
      return this.labelClass
        ? this.labelClass
        : 'text-grey-darker text-xs font-semibold w-full block mb-2'
    },
    inputStyles() {
      const classes = {}
      classes[this.errorClass] = this.hasErrors
      classes[this.inputClass] = !!this.inputClass
      classes[this.defaultClass] = !this.inputClass
      classes['text-right'] = this.mode === 'currency'
      classes['cursor-not-allowed bg-grey-lighter'] = !!this.disabled
      classes[`py-${this.paddingY}`] = true
      classes[`px-${this.paddingX}`] = true
      return classes
    },
    onBlur() {
      this.$emit('input', this._value)
      if (this.errors)
        this.errors.$touch()
    },
  },
}
</script>

<template>
  <div class="flex flex-col w-full">
    <label v-if="label" :class="labelStyles()" class="leading-tight">
      {{ label }} <span v-if="required" class="text-red">*</span>
    </label>
    <div class="relative">
      <input
        :type="inputType"
        :name="name"
        :disabled="disabled"
        autocomplete="on"
        :class="inputStyles()"
        class="block w-full py-1 pl-2 pr-1 text-xs font-normal bg-white border rounded-md border-alto text-grey-darker"
        :placeholder="placeholder"
        :maxLength="maxLength"
        @blur="onBlur"
      >

      <button
        v-show="showPasswordVisibility"
        class="toggle-password"
        @click.prevent="togglePasswordVisibility"
      >
        <i
          :class="`text-grey-darker ${showPassword ? 'fas fa-eye-slash' : 'fas fa-eye'}`"
        />
      </button>
    </div>
    <!-- <span
      v-if="hasErrors && _errors.length"
      class="text-xs capitalize text-red-light"
    >
      {{ _errors[0].message }}
    </span> -->
  </div>
</template>

<style scoped>
.toggle-password {
  position: absolute;
  right: 10px; /* Adjust as needed */
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
}
</style>
